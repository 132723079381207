import React, { useCallback, useMemo, useContext } from "react";
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";
import { ThemeContext } from '../App'; // Adjust the path based on your project structure

import "../styles/LayoutParticles.css"; // Import your CSS for particles styling

const LayoutParticles = () => {
  const { theme } = useContext(ThemeContext);

  const options = useMemo(() => ({
    background: {
      color: theme === "light" ? "#FFFFFF" : "#000000",
    },
    fullScreen: {
      enable: true, // Make particles cover the entire screen
      zIndex: 0, // Ensure particles are behind other content
    },
    particles: {
      number: {
        value: 100,
        density: {
          enable: true,
        },
      },
      size: {
        value: { min: 1, max: 3 },
      },
      color: {
        value: theme === "light" ? "#B19CD9" : "#808080",
      },
      move: {
        enable: true,
        direction: "none",
        speed: 2,
        outModes: {
          default: "out", // Allows particles to move out of the viewport
        },
        random: true,
      },
    },
  }), [theme]);

  const particlesInit = useCallback((engine) => {
    loadSlim(engine);
  }, []);

  return (
    <div id="particles-container">
      <Particles id="particles" init={particlesInit} options={options} />
    </div>
  );
};

export default LayoutParticles;
