import React, { createContext, useState, useEffect } from 'react';
import './App.css';
import LayoutParticles from "./context/LayoutParticles";
import Header from './components/NavBar';
import LandingPage from './components/pages/LandingPage';
import AboutMePage from './components/pages/AboutMe';
import WorksPage from './components/pages/Works';
import ContactPage from './components/pages/Contact';
import Footer from './components/Footer';

export const ThemeContext = createContext(null);

function App() {
  const [theme, setTheme] = useState(() => {
    // Retrieve the theme from localStorage if it exists, otherwise default to dark
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? savedTheme : 'dark';
  });

  useEffect(() => {
    // Save the theme to localStorage whenever it changes
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div className={`App ${theme}`}>
        <Header />
        <LayoutParticles />
        <div className="pages">
          <LandingPage />
          <div id="about" className="page-section"><AboutMePage /></div>
          <div id="works" className="page-section"><WorksPage /></div>
          <div id="contact" className="page-section"><ContactPage /></div>
        </div>
        <Footer />
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
