import React, { useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { ThemeContext } from '../../App';

import "@fontsource/roboto";
import languagesLightMode from "../../assets/languages-light-mode.webp";
import languagesDarkMode from "../../assets/languages-dark-mode.webp";
import "../../styles/AboutMe.css";

function AboutMe() {
    const { theme } = useContext(ThemeContext); // Destructure theme from context

    const manageLanguages = () => {
        const languagesImage = theme === "dark" ? languagesDarkMode : languagesLightMode;
        return <img src={languagesImage} width='391' height='587' alt="Languages" className='languages-image' />;
    }

    return (
        <Container fluid className="AboutMeContainer">
            <Row className="justify-content-center">
                <Col className="text-center position-relative mb-4">
                    <div className='rectangle'></div>
                    <h1 className='about' style={{ fontFamily: 'Roboto', fontWeight: '900', fontSize: '60px', marginBottom: '3rem' }}>About</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={{ span: 8, offset: 2 }} lg={{ span: 4, offset: 1 }} className='left-side'>
                    <p className='about-me-text'>
                        My name is Steven! I just graduated from the University of Wisconsin-Madison in May. I'm looking to step into the field
                        of software developing and/or building User Interfaces. I'm originally from Chicago, but family moved to Naples, Florida.
                        I enjoy collaborating with others and bringing minds together; however, I can work independently as well. 
                        <br />
                        <br />
                        When I'm not coding, I like to play video games, play and watch sports, listen to music, and hang out with friends
                        and family.
                    </p>
                </Col>
                <Col xs={12} lg={6} className="text-center">
                    {manageLanguages()}
                </Col>
            </Row>
        </Container>
    );
}

export default AboutMe;
