import React from 'react';
import Container from 'react-bootstrap/Container';

import '../../styles/LandingPage.css';

function LandingPage() {
    return (
        <Container fluid className="LandingPageContainer">
            <p className='Text'>
                Hello, I'm <span className="Steven-Le">Steven Le</span>.<br/>
                I'm a full stack web developer.
            </p>
        </Container>
    )
}

export default LandingPage;
