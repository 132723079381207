import React from 'react';
import Stack from "react-bootstrap/Stack";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'

import "@fontsource/roboto";
import "../styles/Footer.css";
import githubIcon from "../assets/github-mark-white.webp";
import linkedinIcon from "../assets/In-White-14@2x.webp";
import spotifyIcon from "../assets/Spotify_Primary_Logo_RGB_White.webp";
import xLogo from "../assets/logo-white.webp";

const Footer = () => {
    return (
        <footer className='footer'>
            <Container fluid>
                <Row className='text-white p-4'>
                    <Col className="left-side">
                        <p>&copy; {new Date().getFullYear()} Steven Le. All rights reserved.</p>
                    </Col>
                    <Col className='center'>
                        <p>Email: lestevencs01@gmail.com</p>
                    </Col>
                    <Col className='right-side'>
                        <Stack direction='horizontal' gap={3}>
                            <a href="https://github.com/sle2001" aria-label="Links to my Github">
                                <Image src={githubIcon} width='29' height='28' alt='my github' />
                            </a>
                            <a href="https://www.linkedin.com/in/steven-le-b55709221/" aria-label="Links to my LinkedIn">
                                <Image src={linkedinIcon} height='28' width='28' alt='my linkedin'/>
                            </a>
                            <a href="https://open.spotify.com/user/stevenlesuper?si=9f3dc2ddbe7845a2" aria-label="Links to my spotify">
                                <Image src={spotifyIcon} width='28' height='28' alt='my spotify'/>
                            </a>
                            <a href="https://x.com/stele2001" aria-label="Links to my X">
                                <Image src={xLogo} width='28' height='29' alt='my X'/>
                            </a>
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;