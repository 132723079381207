import React, { useState, useRef } from 'react';
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import emailjs from '@emailjs/browser';

import "@fontsource/roboto";
import "../../styles/Contact.css"

function Contact() {
    const [validated, setValidated] = useState(false);
    const formRef = useRef();

    const handleSubmit = (e) => {

        const form = e.currentTarget;
        if(form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            e.preventDefault();
            emailjs
                .sendForm('contact_service', 'contact_form', formRef.current, {
                    publicKey: 'CcvKbv5IyT1_R27Dn'
                }) 
                .then(
                    ()=> {
                        console.log('SUCCESS');
                        formRef.current.reset();
                        setValidated(false); // Reset validation state
                    },
                    (error) => {
                        console.log('FAILED...', error.text);
                    },
                );
        }

        setValidated(true);
    };

    return (
        <Container fluid className="ContactContainer" style={{ marginBottom: "3rem"}}>
            <Row className="justify-content-center">
                <Col className="text-center position-relative mb-4">
                    <div className='rectangle'></div>
                    <h1 style={{ fontFamily: 'Roboto', fontWeight: '900', fontSize: '60px', marginBottom: '3rem' }}>Contact</h1>
                </Col>
            </Row>

            <Container className='form-container'>
                <Form noValidate ref={formRef} validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group xs={12} lg={6} as={Col} controlId="formGridFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control 
                                required
                                type="text"
                                name='firstName'
                                placeholder='First name'
                            />
                            <Form.Control.Feedback type="invalid">Please provide a first name</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control 
                                required
                                type="text"
                                name='lastName'
                                placeholder='Last name'
                            />
                            <Form.Control.Feedback type="invalid">Please provide a last name</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Form.Group className="mb-3" controlId="formGridEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                            required
                            type="email"
                            name="user_email"
                            placeholder='username@example.com' 
                        />
                        <Form.Control.Feedback type="invalid">Please provide an email</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId='formGridMessage'>
                        <Form.Label>Message</Form.Label>
                        <Form.Control 
                            required
                            as="textarea" 
                            rows={5}
                            name='message'
                            placeholder='Message' 
                        />
                        <Form.Control.Feedback type="invalid">Please write a message</Form.Control.Feedback>
                    </Form.Group>
                    <br/>
                    <Button type="submit" value="send">Submit</Button>
                </Form>
            </Container>
        </Container>
    )
}

export default Contact;