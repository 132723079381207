import React, { useContext } from 'react';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "@fontsource/roboto";
import "../styles/NavBar.css";
import ReactSwitch from "react-switch";
import { ThemeContext } from '../App'; // Import the ThemeContext

const NavBar = () => {
    const { theme, toggleTheme } = useContext(ThemeContext); // Consume the context values

    return (
        <Navbar collapseOnSelect data-bs-theme="dark" expand="lg" fixed="top" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand className='navbar-brand' href="#">Steven Le</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mx-auto">
                        <Nav.Link href="#about">About</Nav.Link>
                        <Nav.Link href="#works">Works</Nav.Link>
                        <Nav.Link href="#contact">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <div className='switch me-auto'>
                    <label htmlFor="theme-switch" className='theme-label'>{theme === "light" ? "Light Mode" : "Dark Mode"}</label>
                    <ReactSwitch id="theme-switch" onChange={toggleTheme} checked={theme === "dark"} />
                </div>
            </Container>
        </Navbar>
    );
}

export default NavBar;
