import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'

import "@fontsource/roboto";
import "../../styles/Works.css"
import badgerchat from "../../assets/BadgerChat.webp";
import badgerbuddies from "../../assets/badgerbuddies.webp";
import badgerchatagent from "../../assets/BadgerChatAgent.webp";
import badgermartchatagent from "../../assets/BadgerMartChatAgent.webp";

function Works() {
    return (
        <Container fluid className="WorksContainer">
            <Row className="justify-content-center">
                <Col className="text-center position-relative mb-4">
                    <div className='rectangle'></div>
                    <h1 style={{ fontFamily: 'Roboto', fontWeight: '900', fontSize: '60px', marginBottom: '4rem' }}>Works</h1>
                </Col>
            </Row>
            <Row className='leftside-project'>
                <Col xs={12} md={8} className='leftside-image-col'>
                    <Image className='leftside-image' src={badgerchat} alt='Badger Chat' />
                </Col>
                <Col xs={12} md={4} className='left-side-text'>
                    <a className="project-links" href='https://badgerchat.stevenle.dev' aria-label='Link to website'>
                        <h2 style={{ fontFamily: 'Roboto', fontWeight: '900' }}>Badger Chat</h2>
                    </a>
                    <br/>
                    <p style={{ fontFamily: 'Georgia, Times New Roman, Times, serif', fontSize: '18px',marginLeft: '15px' }}>
                        A chatroom application that is built with vite, react, bootstrap (and react-bootstrap), and CS571 API
                    </p>
                </Col>
            </Row>
            <Row className='rightside-project'>
                <Col xs={12} md={4} className='right-side-text'>
                    <a className='project-links' href="https://badgermart.witai.stevenle.dev" aria-label='Link to website'>
                        <h2 style={{ fontFamily: 'Roboto', fontWeight: '900' }}>Badger Mart Chat Agent</h2>
                    </a>
                    <br/>
                    <p style={{ fontFamily: 'Georgia, Times New Roman, Times, serif', fontSize: '18px',marginLeft: '15px' }}>
                        A chat agent for Badger Mart web application that is built with vite, react, bootstrap (and react-bootstrap), CS571 API, 
                        and WitAI.
                    </p>
                </Col> 
                <Col xs={12} md={8} className='rightside-image-col'>
                    <Image className='rightside-image' src={badgermartchatagent} alt='Badger Mart with WitAI' />
                </Col>
            </Row>
            <Row className='leftside-project'>
                <Col xs={12} md={8} className='leftside-image-col'>
                    <Image className='leftside-image' src={badgerbuddies} alt='Badger Buddies' />
                </Col>
                <Col xs={12} md={4} className='left-side-text'>
                    <a className='project-links' href="https://badgerbuddies.stevenle.dev" aria-label='Link to website'>
                        <h2 style={{ fontFamily: 'Roboto', fontWeight: '900' }}>Badger Buddies</h2>
                    </a>
                    <br/>
                    <p style={{ fontFamily: 'Georgia, Times New Roman, Times, serif', fontSize: '18px',marginLeft: '15px' }}>
                        A redesign of The Madison Cat Project web application that is built with vite, react, bootstrap (and react-bootstrap), and CS571 API
                    </p>
                </Col>
            </Row>
            <Row className='rightside-project'>
            <Col xs={12} md={4} className='right-side-text'>
                    <a className='project-links' href="https://badgerchat.witai.stevenle.dev" aria-label='Link to website'>
                        <h2 style={{ fontFamily: 'Roboto', fontWeight: '900' }}>Badger Chat Agent</h2>
                    </a>
                    <br/>
                    <p style={{ fontFamily: 'Georgia, Times New Roman, Times, serif', fontSize: '18px',marginLeft: '15px' }}>
                        A chat agent for Badger Mart web application that is built with vite, react, bootstrap (and react-bootstrap), CS571 API, 
                        and WitAI. It also implements VUI.
                    </p>
                </Col> 
                <Col xs={12} md={8} className='rightside-image-col'>
                    <Image className='rightside-image' src={badgerchatagent} alt='Badger Chat with WitAI' />
                </Col>
            </Row>
        </Container>
    )
}

export default Works;